import React from "react";
import emblem from './asset-emblem-web-min.png';

const App: React.FC = () => {
  return <section className="flex items-center justify-center">
    <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="">
          <div className="container grid grid-cols-3 gap-3">
            <div className="w-full col-span-3 md:col-span-2 py-14">
              <h2 className="text-lg font-semibold text-black">Chris Webb</h2>
              <p className="text-sm font-normal text-black/60">
                Lead Front End Engineer with 25 years experience building
                award winning website applications.
              </p>
              <ul role="list" className="grid grid-cols-1 gap-6 py-6 mt-6 border-#aaa-700 border-y sm:grid-cols-2">
                <li>
                  <div>
                    <h3 className="text-sm font-medium">
                      <a href="https://twitter.com/fullstackjsdev" className="text-black focus:outline-none">
                        Twitter
                      </a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div>
                    <h3 className="text-sm font-medium">
                      <a href="https://www.linkedin.com/in/chris-webb-syd/" className="text-black focus:outline-none">
                        LinkedIn
                      </a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div>
                    <h3 className="text-sm font-medium">
                      <a href="https://www.youtube.com/fsjsd" className="text-black focus:outline-none">
                        YouTube
                      </a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div>
                    <h3 className="text-sm font-medium">
                      <a href="https://github.com/fsjsd/" className="text-black focus:outline-none">
                        GitHub
                      </a>
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full col-span-1 hidden md:block">
              <img src={emblem} alt="Full Stack Javascript Development logo" className="object-fill max-h-80" />
            </div>
          </div>
          <div className="max-w-lg mt-6">
            <h2 className="text-lg font-semibold text-black">Work</h2>
            <p className="text-sm font-normal text-black/60">
              Collection of active projects.
            </p>
          </div>
          <ul role="list" className="grid grid-cols-1 gap-6 py-6 mt-6 border-#aaa-700 border-y sm:grid-cols-2">
            <li>
              <div>
                <h3 className="text-sm font-medium">
                  <a href="https://fsjsd.github.io/frontend-patterns/" className="text-black focus:outline-none">
                    Front end patterns
                  </a>
                </h3>
                <p className="mt-1 text-sm font-normal text-black/60">
                  Collection of modern, front end ux patterns built in React. Enterprise grade
                  code (&gt;96% test coverage) with full CI pipeline.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>;
};

export default App;
